import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthToken = () => {
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [email, setEmail] = useState(() => localStorage.getItem('email') || null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedEmail = localStorage.getItem('email');
    if (storedToken) {
      if (validateToken(storedToken)) {
        setToken(storedToken);
        setEmail(storedEmail); 
      } else {
        removeToken();
        navigate('/admin-login'); 
      }
    }
    setLoading(false);
  }, [navigate]);

  const validateToken = (token) => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp > Date.now() / 1000; 
  };

  const updateToken = (newToken,  newEmail = null) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
    if (newEmail) {
      localStorage.setItem('email', newEmail);
      setEmail(newEmail);
    }
  };

  const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    setToken(null);
    setEmail(null);
  };

  return {token, email, loading, updateToken, removeToken };
};
  
export default useAuthToken;