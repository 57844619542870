import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockOpenOutline,
} from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { LIVE_URL } from "../../config/config";
import useAuthToken from "../../hooks/useAuthToken";
import styles from "../adminlogin/css/AdminLogin.module.scss";
import logoImage from "../assets/let's meet logo web 8.png";

const AdminLogin = () => {
  // Custom hook to fetch token and loading state
  const { token, loading } = useAuthToken();

  // State variables to manage form inputs and errors
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  // const [error, setError] = useState("");
  const navigate = useNavigate();

  // Redirect to home if the user is already logged in
  useEffect(() => {
    if (!loading && token) {
      navigate("/home");
    }
  }, [token, loading, navigate]);

  // Show and hide popup message with a fade effect
  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById("popup-message");
      if (popupElement) {
        popupElement.style.opacity = "1";
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = "0";
        }
        setTimeout(() => {
          setPopupMessage("");
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);

  // Function to validate email and password fields
  const validateFields = () => {
    let valid = true;

    if (!email) {
      setEmailError("Email Cannot Be Blank");
      valid = false;
    } else if (email.startsWith(" ") || email.endsWith(" ")) {
      setEmailError("Email cannot have leading or trailing spaces");
      valid = false;
    } else if (!/^[\w-.]+@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      setEmailError("Invalid Email Format");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password Cannot Be Blank");
      valid = false;
    } else {
      setPasswordError("");
    }

    return valid;
  };

  // Function to handle login submission
  const submitData = async () => {
    if (!validateFields()) {
      return;
    }

    try {
      const response = await axios.post(
        `${LIVE_URL}/admin/login`,
        {
          email,
          password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = response.data;
      localStorage.setItem("homeActive", "true");
      localStorage.setItem("token", data.token);
      navigate("/home");
    } catch (error) {
      const message =
        error.response?.status === 401
          ? "Please enter a valid email and password"
          : error.response?.data?.message || error.message;
      setPopupMessage(message);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        {/* Popup message */}
        <div
          id="popup-message"
          className={`${styles.login_error_message} ${
            popupMessage.includes("success") ? styles.success : styles.error
          }`}
        >
          {popupMessage && <p>{popupMessage}</p>}
        </div>
        {/* Login Form */}
        <div className={styles.login}>
          <div className={styles.logo}>
            <img src={logoImage} alt="Logo" />
          </div>
          <p>Please Login To Continue</p>
          {/* Email Input */}
          <div className={styles.input_icon}>
            <MdOutlineEmail />
            <input
              type="email"
              placeholder="Enter Email Here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={emailError ? styles.error_input : ""}
            />
            {emailError && (
              <span className={styles.error_message}>{emailError}</span>
            )}
          </div>
          <br />
          {/* Password Input */}
          <div className={styles.input_icon}>
            <IoLockOpenOutline />
            <input
              type={showPassword ? "text" : "Password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={passwordError ? styles.error_input : ""}
            />
            {showPassword ? (
              <IoEyeOutline
                className={styles.eye_icon}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <IoEyeOffOutline
                className={styles.eye_icon}
                onClick={() => setShowPassword(true)}
              />
            )}
            {passwordError && (
              <span className={styles.error_message}>{passwordError}</span>
            )}
          </div>
          {/* Forgot Password Link */}
          <div className={styles.forgot_pass}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <br />
          {/* Login Button */}
          <button onClick={submitData}>Login</button>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
