import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockOpenOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { LIVE_URL } from "../../config/config";
import useAuthToken from "../../hooks/useAuthToken";
import styles from "./css/ResetPassword.module.scss";

const ResetPasswordPage = () => {
  // Extract the stored email from custom hook useAuthToken
  const { email: storedEmail } = useAuthToken();

  // Initialize state variables
  const [email, setEmail] = useState(storedEmail || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const navigate = useNavigate();

  // useEffect to handle the display and automatic fading of popup messages
  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById("popup-message");
      if (popupElement) {
        popupElement.style.opacity = "1";
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = "0";
        }
        setTimeout(() => {
          setPopupMessage("");
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);

  // Function to handle the submission of the password reset form
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setPopupMessage("");

    // Validate inputs
    let hasError = false;

    if (!password) {
      setPasswordError("Password Is Required");
      setPopupMessage("Password Is Required");
      hasError = true;
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Password Is Required");
      setPopupMessage("Password Is Required");
      hasError = true;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Confirm Password Does Not Match");
      setPopupMessage("Confirm Password Does Not Match");
      hasError = true;
    }

    // Stop submission if there are validation errors
    if (hasError) {
      return;
    }

    try {
      await axios.post(
        `${LIVE_URL}/admin/reset-password`,
        { email, password, confirm_password: confirmPassword },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      setPopupMessage(
        "Password Reset Successfully. You Can Now Log In With Your New Password."
      );
      setTimeout(() => {
        navigate("/admin-login");
      }, 2000);
    } catch (error) {
      const errors = error.response?.data?.errors || {};
      setEmailError(errors.email ? errors.email[0] : "");
      setPasswordError(errors.password ? errors.password[0] : "");
      setConfirmPasswordError(
        errors.confirm_password ? errors.confirm_password[0] : ""
      );
      setPopupMessage(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <div className={styles.wrapper}>
      {/* Popup message */}
      <div
        id="popup-message"
        className={`${styles.popup_message} ${
          popupMessage.toLowerCase().includes("success")
            ? styles.success
            : styles.error
        }`}
      >
        {popupMessage && <p>{popupMessage}</p>}
      </div>
      <div className={styles.resetPasswordPage}>
        <h2>Reset Password</h2>
        {/* Form to handle password reset */}
        <form onSubmit={handlePasswordSubmit}>
          <div className={styles.formGroup}>
            <div className={styles.inputWrapper}>
              <IoLockOpenOutline className={styles.icon} />
              {/* Input for the new password */}
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={passwordError ? styles.error_input : ""}
              />
              <span
                className={styles.eyeIcon}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </span>
            </div>
            {/* {passwordError && <span className={styles.error_message}>{passwordError}</span>} */}
          </div>
          <div className={styles.formGroup}>
            <div className={styles.inputWrapper}>
              <IoLockOpenOutline className={styles.icon} />
              {/* Input for confirming the new password */}
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={confirmPasswordError ? styles.error_input : ""}
              />
              <span
                className={styles.eyeIcon}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </span>
            </div>
            {/* {confirmPasswordError && <span className={styles.error_message}>{confirmPasswordError}</span>} */}
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
