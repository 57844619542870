import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LIVE_URL } from "../../../config/config";
import useAuthToken from '../../../hooks/useAuthToken';
import Spinner from "../spinner/Spinner";
import styles from "./css/UserInfo.module.scss";

const UserInfo = () => {
  const { id } = useParams();
  const { token } = useAuthToken();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${LIVE_URL}/user/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setUserData(data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!loading && token) {
      fetchUserData();
      setLoading(false);
    }
  }, [token, loading, id]);

  // Render a message if no user data is available
  if (!userData) {
    return <Spinner />;
  }

  const profileImage = userData.user_images && userData.user_images.length > 0
    ? userData.user_images[0].image
    : null;

  return (
    <>
      <div className={`${styles.user_info}`}>
        <header>
          <h2>Profile Overview</h2>
        </header>
        <section className={`${styles.section}`}>
          <div className={`${styles.profile_image}`}>
            <div className={`${styles.user_profile}`}>
              <p>My Profile</p>
              <div className={`${styles.user_image}`}>
                {profileImage ? (
                  <img src={profileImage} alt="User" />
                ) : (
                  <div className={styles.no_image_placeholder}>No Image Available</div>
                )}

                <h5>{userData.firstname} {userData.lastname}</h5>
              </div>
            </div>
            <div className={`${styles.payment_info}`}>
              <p>Other Picture</p>
              {userData.user_images && userData.user_images.slice(0, 4).map((image, index) => (
                <img key={index} src={image.image} alt={`UserImage${index + 1}`} />
              ))}
            </div>
            <div className={`${styles.plan_info}`}>
              <div className={`${styles.plan_button}`}>
                <p>Plan Information</p>
                {/* <button>{userData.plan_name || "No Membership"}</button> */}
              </div>
              <div className={`${styles.plan_date}`}>
                <div>
                  <p>Plan Start Date:</p>
                  <h6>{userData.plan_start_date || "N/A"}</h6>
                </div>
                <div>
                  <p>Plan End Date:</p>
                  <h6>{userData.plan_end_date || "N/A"}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.other_info}`}>
            <h1>Other Information</h1>
            <div className={`${styles.header}`}>
              <div className={styles.info_item}>
                <h4>Profile Bio :</h4>
                <p>{userData.bio}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Birth Date :</h4>
                <p>{new Date(userData.dob).toLocaleDateString()}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Gender :</h4>
                <p>{userData.gender}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Height :</h4>
                <p>{userData.height}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Search Preference :</h4>
                <p>{userData.interest_in}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Relation Goal :</h4>
                <p>{userData.relation_goal_data.map(goal => goal.title).join(", ")}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Religion :</h4>
                <p>{userData.religion_data.map(religion => religion.title).join(", ")}</p>
                {/* <p>{userData.religion}</p> */}
              </div>
              <div className={styles.info_item}>
                <h4>Zodiac sign :</h4>
                <p>{userData.zodiac_sign}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Interests :</h4>
                <p>{userData.interests_data.map(interest => interest.title).join(", ")}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Languages :</h4>
                <p>{userData.languages_data.map(language => language.title).join(", ")}</p>
              </div>
              <div className={styles.info_item}>
                <h4>Age Range :</h4>
                <p>{userData.age_range}</p>
              </div>
            </div>
          </div>
        </section>

        <main className={`${styles.main}`}>
          <div className={`${styles.user_interest}`}>
            <p>Interest</p>
            <div className={styles.userContainer}>
              {userData.interests_data && userData.interests_data.slice(0, 5).map((interest, index) => (
                <div key={index} className={styles.interestItem}>
                  <img src={interest.image} alt={`interest${index + 1}`} />
                  <p>{interest.title}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={`${styles.user_language}`}>
            <p>Language Known</p>
            <div className={styles.languageContainer}>
              {userData.languages_data && userData.languages_data.map((language, index) => (
                <div key={index} className={styles.languageItem}>
                  <img src={language.image} alt={`language${index + 1}`} />
                  <p>{language.title}</p>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default UserInfo
