import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URL } from '../../../config/config';
import useAuthToken from '../../../hooks/useAuthToken';
import Spinner from '../spinner/Spinner';
import styles from "./css/AddFAQ.module.scss";

const AddFAQ = () => {
  // Custom hook to get the authentication token
  const { token } = useAuthToken();

  // Get the FAQ ID from the URL parameters
  const { id } = useParams();

  // State variables to manage form data and component states
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to reset form fields
  const resetForm = () => {
    setQuestion('');
    setAnswer('');
    setSelectedStatus('');
    setPopupMessage('');
    setErrors({});
  };

  // Fetch FAQ data if an ID is present 
  useEffect(() => {
    if (id) {
      const fetchFaqData = async () => {
        try {
          setLoading(true);
          const response = await fetch(`${LIVE_URL}/admin/faq/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            const faqData = data.data;
            setQuestion(faqData.question);
            setAnswer(faqData.answer);
            setSelectedStatus(faqData.status ? 'true' : 'false');
          }
        } catch (error) {
          console.error('Error fetching faq data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchFaqData();
    } else {
      resetForm();
      setLoading(false);
    }
  }, [id, token]); // Run effect when `id` or `token` changes

  // Effect to manage popup message
  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById('popup-message');
      if (popupElement) {
        popupElement.style.opacity = '1';
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = '0';
        }
        setTimeout(() => {
          setPopupMessage('');
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);

  // Handle input change for the question field
  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, question: '' }));
  };

  // Handle input change for the answer field
  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, answer: '' }));
  };

  // Handle select change for the status field 
  const handleSelectChange = (event) => {
    setSelectedStatus(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, selectedStatus: '' }));
  };

  // Handle form submission to either add or update an FAQ
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

     // Validate form fields and collect errors
    const newErrors = {};
    if (!question) newErrors.question = "🚫 Please provide a question!";
    if (!answer) newErrors.answer = '💡 Please complete this.';
    if (!selectedStatus) newErrors.selectedStatus = '❗️Status field cannot be blank.';
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setPopupMessage('Please fill in all required fields');
      setLoading(false);
      return;
    }

     // Prepare FAQ data to send in the API request
    const faqData = {
      question,
      answer,
      status: selectedStatus === 'true',
    };

    try {
      const url = id
        ? `${LIVE_URL}/admin/faq/update`
        : `${LIVE_URL}/admin/faq/create`;

      const body = id ? { id, ...faqData } : faqData;

      const method = 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      await response.json();
      if (response.ok) {
        const successMessage = id ? 'FAQ updated successfully.' : 'FAQ saved successfully.';
        navigate(`/home/list-faq?message=${encodeURIComponent(successMessage)}`);
      } else {
        setPopupMessage(`Failed to save faq. Status: ${response.status}`);
      }
    } catch (error) {
      setPopupMessage('Error saving faq.');
    } finally {
      setLoading(false);
    }
  };

   // Options for the status select dropdown
  const options = [
    { value: 'true', label: 'Publish' },
    { value: 'false', label: 'UnPublish' }
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {/* Popup message container */}
      <div id="popup-message" className={`${styles.update_message} ${popupMessage.includes('success') ? styles.success : styles.error}`}>
        {popupMessage && (
          <p>{popupMessage}</p>
        )}
      </div>
      {/* Main FAQ management form */}
      <div className={`${styles.add_faq}`}>
        <header>
          <h2>FAQ Management</h2>
        </header>
        <section className={`${styles.section}`}>

          <label htmlFor="question">Enter Question</label><br />
          <input type="text"
            placeholder='Enter Question'
            id="question"
            value={question}
            onChange={handleQuestionChange}
          />
          {errors.question && <p className={styles.error}>{errors.question}</p>}
          <br /><br />
          <label htmlFor="answer">Enter Answer</label><br />
          <textarea
            className="scrollable_textarea"
            id="answer"
            value={answer}
            onChange={handleAnswerChange}
          ></textarea>
          {errors.answer && <p className={styles.error}>{errors.answer}</p>}
          <br /><br />
          <label htmlFor="status">Select Status</label><br />
          <select value={selectedStatus} onChange={handleSelectChange}>
            <option value="">Choose...</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.selectedStatus && <p className={styles.error}>{errors.selectedStatus}</p>}
          <button onClick={handleSubmit}>{id ? 'Update FAQ' : 'Add FAQ'}</button>
        </section>
      </div>
    </>
  )
}

export default AddFAQ
