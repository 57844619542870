import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import styles from './css/Home.module.scss';

const Home = () => {

    // useState hook to manage the state of the sidebar (open/closed)
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    // const [isDarkMode, setIsDarkMode] = useState(false);

    // const toggleTheme = () => {
    //     setIsDarkMode(!isDarkMode);
    // };

    // Function to toggle the sidebar open/closed state
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);

        const sidebarElement = document.querySelector('.sidebar');
        const sidebarLogo = document.querySelector('.logo');

        if (sidebarElement && sidebarLogo) {
            if (isSidebarOpen) {
                sidebarElement.style.marginLeft = '0px';
                sidebarLogo.style.marginLeft = '0px';
            } else {
                sidebarElement.style.marginLeft = '-260px';
                sidebarLogo.style.marginLeft = '-260px';
            }
        }
    };

    return (
        <>
            {/* Main container for the layout */}
            <div className={`${styles.container}`}>
                {/* Sidebar component with isSidebarOpen passed as a prop */}
                <Sidebar isSidebarOpen={isSidebarOpen} />
                <div className={`${styles.content_div}`} >
                    {/* Navbar component with toggleSidebar function passed as a prop */}
                    <Navbar toggleSidebar={toggleSidebar}  />
                    <div className={`${styles.content}`}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home
